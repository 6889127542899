#our-services {
  .main {
    background-image: url("../../assets/agent-assets/matan/home-img-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    width: 100vw;
    height: 40vh;
    filter: brightness(30%);
  }

  .our-services-container {
    background: #fff;
    box-shadow: inset 0 106px 36px -116px rgba(0, 0, 0, 0.14);

    .ant-typography {
      color: #231F20;
      margin: 0;

      &.title {
        color: $app-primary;
        padding-bottom: 40px;
      }

      &.sub-title {
        color: $app-primary;
      }

      &.sub-description {
        padding: 0 20px 0 0;
      }

      &.description{
        font-size: 16px;
      }
    }

    .details {
      display: block;
      padding-top: 100px;
      max-width: 1360px;
      margin: 0 auto;

      .right {
        width: 100%;
        padding-bottom: 100px;
      }
    }
  }

  @media screen and (max-width: $screen-xxl) {
    .our-services-container {
      .details {
        padding-right: 40px;
      }
    }
  }

  @media screen and (max-width: $screen-xl) {
    .our-services-container {
      padding-right: 0;

      .details {
        padding: 20px;

        .right {
          width: 100%;
        }
      }
    }
  }
}


@media (max-width: $screen-lg) {
  #our-services {
    .main {
      height: 60vh;
    }
    img{
      width: 90%;
    }
  }
}
