@font-face {
  font-family: 'Poppins-Regular';
  src: url('./less/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./less/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./less/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Assistant-Regular';
  src: url('./less/fonts/Assistant/Assistant-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Assistant-Medium';
  src: url('./less/fonts/Assistant/Assistant-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Assistant-Light';
  src: url('./less/fonts/Assistant/Assistant-Light.ttf') format('truetype');
  font-weight: 300;
}

@import "./variables";
@import "./less/App.scss";
@import "./less/footer/Footer.scss";
@import "./less/header/Header.scss";
@import "./less/home/Home.scss";
@import "./less/ourServices/OurServices.scss";
@import "./less/about/About.scss";
@import "./less/contactUsPage/ContactUsPage.scss";

@import "./less/components/Insurance/Insurance.scss";
@import "./less/components/ContactUs/ContactUs.scss";
@import "./less/components/MainTitle/MainTitle.scss";

body {
  background-color: #fff;
  margin: 0;
}

a{
  color:  #005cdc;
}
.ant-card, .ant-menu, .ant-typography {
  font-family: Assistant-Regular !important;
}

.ant-menu-submenu {
  direction: ltr;
}

.ant-menu {
  direction: rtl;
}

.ant-layout-footer {
  margin-top: 100px;
  background: #e1e1e1 !important;
}

@media screen and (max-width: $screen-md) {
  .ant-menu-submenu-popup {
    width: 100vw !important;
    background-color: #fff !important;

    .ant-menu-sub,
    .ant-menu-item {
      font-size: 16px !important;
    }
  }
}


/* Whatapp Wiget Styling*/
._1yCVn, ._2qp0Z{
  right: 0;
  left: 25px !important;
  z-index: 9999;
}

._1mXIm{
  direction: rtl;
}
