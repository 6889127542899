.ant-layout {
  background: transparent;

  .ant-layout-header {
    height: 125px;
    line-height: 87px;
    padding-inline: 10px;
    background: transparent;
    z-index: 3;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      width: 100px;
    }
  }

  .logo-title-card {
    background-color: transparent;
    border: 0;
    color: #fff;

    .ant-card-body {
      padding: 10px;
      margin-top: -50px;

      p {
        text-align: center;
        margin: 0;

        &.title {
          font-size: 26px;
        }

        &.subtitle {
          font-size: 16px;
        }
      }
    }
  }

  .ant-menu {
    display: flex;
    justify-content: center;
    font-size: 19px;
    color: #fff;
    background-color: transparent;


    .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected),
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
      color: #1677ff;
    }

  }

  .social-icons {
    float: left;
    direction: ltr;
    font-size: 25px;
    padding-left: 10px;

    .icon {
      color: #fff;
    }

    button {
      float: left;
      padding-left: 20px;

      a{
        color:#0e6ff7 !important;
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .ant-layout {
    .ant-layout-header {
      height: 60px;
      line-height: 60px;

      .ant-row {
        background: #000 !important;
        margin-left: -11px !important;
        margin-right: -11px !important;
      }

      .ant-space {
        width: 100%;
        justify-content: center;
      }

      .social-icons {
        float: none;
        font-size: 20px;
        padding-left: 0;
        border-bottom: solid 1px #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
      }

      img {
        width: 50px;
      }
    }

    .logo-title-card {
      .ant-card-body {
        padding: 0;
        margin-top: 0;
        position: absolute;
        top: -88px;
        right: 55px;

        p {
          &.title {
            font-size: 22px;
          }

          &.subtitle {
            font-size: 16px;
          }
        }
      }
    }

    .ant-menu {
      padding-left: 15px !important;
      .ant-menu-submenu-title {
        .anticon {
          font-size: 26px !important;
        }
      }
    }

    li {
      .ant-menu-submenu-title {
        width: 100%;
        color: #fff !important;
        border: 1px solid #fff;
        padding: 0px 12px;
        height: 50px;
        line-height: 55px;
        margin-top: 7px;
      }

      &::after {
        display: none;
      }
    }
  }
}
