#home {
  .main {
    filter: brightness(50%);
  }

  .main-boxes {
    position: absolute;
    top: 35vh;
    width: 100%;
    margin: 0 auto;

    .ant-row {
      max-width: 1360px;
      margin: 0 auto;
      text-align: center;

      .ant-card {
        width: 230px;
        padding-top: 50px;
        padding-bottom: 26px;
        margin: 0 auto;
        border-radius: 25px;
        background: rgba(0, 0, 0, 0.4);
        border: 1px solid gray;
        color: #fff;

        &:hover {
          background: rgba(22, 119, 255, 0.4);
          position: absolute;
          right: -20px;
          top: -10px;
          width: 270px;
          height: 240px;
        }

        .ant-card-meta-title {
          color: #fff;
          text-align: center;
        }
      }
    }

    .title {
      h1 {
        color: #fff;
        text-align: center;
        padding-top: 100px;
        font-family: Assistant-Regular;
        font-weight: normal;
      }
    }
  }

  .about-container {
    background: #fff;
    box-shadow: inset 0 106px 36px -116px rgba(0, 0, 0, 0.14);

    .about {
      display: flex;
      min-height: 550px;
      max-height: 550px;
      max-width: 1360px;
      margin: 0 auto;

      .right {
        width: 42%;
        /*padding-top: 50px;*/
        text-align: center;

        img {
          height: 100%;
        }
      }

      .left {
        width: 58%;
        padding-top: 160px;
        h3{
          padding-bottom: 45px;
        }
        .ant-typography {
          color: #231F20;
          margin: 0;
          font-size: 18px;
          &.title {
            color: $app-primary;
          }

          &.description {
            padding: 0 0 0 45px;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .main-boxes {
    top: 25vh !important;

    .ant-row {
      .ant-card {
        width: 400px !important;
        margin-bottom: 40px !important;
      }
    }
  }
}

@media (max-width: $screen-md) {
  #home {
    .main {
      background-image: url("../../assets/agent-assets/matan/home-img-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      filter: brightness(30%);
      height: 581px !important;
    }

    .main-boxes {
      top: 147px !important;

      .title {
        display: none;
      }

      .ant-row {
        .ant-card {
          width: auto !important;
          border-radius: 0 !important;
          border-color: gray !important;
        }
      }
    }

    .about-container {
      .about {
        .right {
          display: none;
        }

        .left {
          width: 100% !important;
          padding-right: 15px;
        }
      }
    }
  }
}
