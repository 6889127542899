#about {
  .main {
    background-image: url("../../assets/agent-assets/matan/home-img-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    width: 100vw;
    height: 40vh;
    filter: brightness(30%);
  }

  .about-container {
    background: #fff;
    box-shadow: inset 0 106px 36px -116px rgba(0, 0, 0, 0.14);

    .about {
      display: block;
      padding-top: 100px;
      max-width: 1360px;
      margin: 0 auto;

      .upper{
        width: 100%;
        padding-bottom: 50px;
        padding-right: 15px;
        padding-left: 15px;

        .right {
          width: 100%;
          padding-top: 50px;
        }
        .left{
          padding-top: 50px;
          width: 100%;
          .ant-card{
            width: 300px;
            margin: 20px;
            background-color: $app-primary;
            color: #fff;
            padding-top: 20px;
            min-height: 300px;
            .ant-card-meta-title{
              color: #fff;
              text-align: center;
              font-size: 22px;
            }
            .ant-card-meta-description{
              color: #fff;
              text-align: center;
              font-size: 16px;
              background-color: $app-primary;
            }
          }
        }
        .ant-typography {
          color: #231F20;
          margin: 0;

          &.title {
            color: $app-primary;
          }

          &.description {
            padding: 25px 0 0 45px;
          }
        }
      }


      .left {
        width: 100%;
        padding-bottom: 50px;
        padding-right: 15px;
        padding-left: 15px;

        .ant-typography {
          color: #231F20;
          margin: 0;

          &.title {
            color: $app-primary;
          }

          &.description {
            padding: 25px 0 0 45px;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-lg) {
  #about {
    .main {
      height: 60vh;
    }
    .about-container {
      .about {
        .upper{
          display: block;
        }
        .left, .right {
          width: 100% !important;
          padding-right: 15px;
        }
        .left{
          .ant-card{
            width: 100% !important;
            margin: 20px 0 0 0 !important;
          }
        }
      }
    }
  }
}
