#contact-us-page {
  .main {
    background-image: url("../../assets/agent-assets/matan/home-img-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    width: 100vw;
    height: 40vh;
    filter: brightness(30%);
  }

  .contact-us-container {
    background: #fff;
    box-shadow: inset 0 106px 36px -116px rgba(0, 0, 0, 0.14);
    padding: 100px 0px;

    .contact {
      display: flex;
      max-width: 1360px;
      margin: 0 auto;

      .left {
        width: 42%;
        .ant-col {
          margin: 10px 0;
        }
        button{
          background-color: $app-primary;
          &:hover{
            color: #fff;
          }
        }
      }

      .right {
        width: 58%;
        h3{
          padding-bottom: 45px;
        }
        .description{
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: $screen-lg) {
  #contact-us-page {
    .main {
      height: 60vh;
    }
    .contact-us-container {
      .contact {
        display: initial;
        .right, .left {
          width: 90% !important;
          margin: 10px;
        }
      }
    }
  }
}
