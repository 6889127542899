#footer {
  margin: 0 auto;

  .our-service {
    .ant-typography {
      margin-left: 60px;
    }
  }

  .site-navigation {
    .ant-list-item {
      border: none !important;
      padding: 8px 0;
    }
  }

  .contact {
    .customer-logo {
      width: 100px;
    }

    .logo-title-card {
      color: #000;

      .ant-card-body {
        padding-top: 34px;

        p {
          text-align: center;
          margin: 0;

          &.title {
            font-size: 26px;
          }

          &.subtitle {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  #footer {
    .contact {
      .logo-title-card {
        .ant-card-body {
          min-width: 167px;
          top: -68px;
          right: -5px;
        }
      }
    }
  }
}
