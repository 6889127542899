.insurance {
  padding: 100px 15px 100px 15px;
  max-width: 90vw;
  margin: 0 auto;

  .box {
    width: 10vw;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    height: 100px;
    padding: 0px;
    background-size: contain;
    position: relative;
    display: inline-block;

    &.as-invest {
      background: url("../../../assets/agent-assets/InsuranceCompanies/as-invest.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.medi-care {
      background: url("../../../assets/agent-assets/InsuranceCompanies/medi-care.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.ayalon-ins {
      background: url("../../../assets/agent-assets/InsuranceCompanies/ayalon-ins.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.harel-group {
      background: url("../../../assets/agent-assets/InsuranceCompanies/harel-group.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.menoramivt {
      background: url("../../../assets/agent-assets/InsuranceCompanies/menoramivt.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.hcsra {
      background: url("../../../assets/agent-assets/InsuranceCompanies/hcsra.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.fnx {
      background: url("../../../assets/agent-assets/InsuranceCompanies/fnx.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.clalbit {
      background: url("../../../assets/agent-assets/InsuranceCompanies/clalbit.png") no-repeat 50% 50%;
      background-size: contain;
    }

    &.migdal {
      background: url("../../../assets/agent-assets/InsuranceCompanies/migdal.png") no-repeat 50% 50%;
      background-size: contain;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .insurance {
    max-width: 100vw;
    padding: 50px 15px 50px 15px;

    .box {
      width: 43vw !important;
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .insurance {
    padding: 50px 15px 50px 15px;

    .box {
      width: 30vw;
    }
  }
}

@media screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
  .insurance {
    padding: 50px 15px 50px 15px;

    .box {
      width: 22vw;
    }
  }
}

@media screen and (min-width: $screen-xl) and (max-width: $screen-xxl) {
  .insurance {
    padding: 50px 15px 50px 15px;

    .box {
      width: 15vw;
    }
  }
}

@media screen and (min-width: $screen-xxl) {
  .insurance {
    padding: 50px 15px 50px 15px;

    .box {
      width: 13vw;
    }
  }
}
